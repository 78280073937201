<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="mb-3 alert alert-warning" v-if="activeUser.status === 1">
          Seu perfil está sendo analisado pela nossa equipe. Assim que for aprovado, você poderá enviar propostas.
        </div>
        <div class="tab">
          <h1>Projeto</h1>
        </div>
        <div class="project-container project_area section" style="margin:0;">
          <div class="left">
            <div class="project_info">
              <div class="project_heading break-word">
                <h2 class="mb-0">{{ project.title }}</h2>
                <p class="date m-0 p-0 mb-2" style="font-size: 10pt">
                  ID: {{ project.id }}
                </p>
                <p class="date">
                  Postagem:
                  {{ formatTimestamp(project.registration_timestamp) }}
                </p>
              </div>
              <div class="wrap-box">
                <p class="break-word" v-html="formatMultilineText(project.description)">
                </p>
                <label></label>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="status box mt-0">
              <h2>
                Status:
                <span class="btn" :class="getBtnStatusClass(project.status)">{{
                  formatProjectStatus(project.status)
                }}</span>
              </h2>
            </div>

            <div class="sm_sclient_img box mb-0">
              <a :href="'/cliente/' + client.id">
                <img :src="getImageURL(client.picture)" v-if="client.picture" />
                <img class="empty-image" v-else />
                <p>{{ client.name }}</p>
              </a>
            </div>
          </div>
        </div>

        <div v-if="project.status === 2 && client.status === 2 && assistant.plan !== 'Free' && assistant.status !== 1">
          <div class="alert alert-warning mt-4" v-if="project.released_contacts > 0">
            <strong>{{ client.name }}</strong> recebeu    {{
              formatPlural(project.active_proposals, "proposta", "propostas")
            }} neste projeto se interessou por
            {{
              formatPlural(project.released_contacts, "proposta", "propostas")
            }}.
          </div>

          <div class="alert alert-warning mt-4" v-else>
            <div v-if="project.active_proposals > 0">
              <strong>{{ client.name }}</strong> recebeu                {{
                formatPlural(project.active_proposals, "proposta", "propostas")
              }} neste projeto mas ainda não se interessou por nenhuma.
            </div>
            <div v-else>
              <strong>{{ client.name }}</strong> ainda não recebeu propostas neste projeto.
            </div>
          </div>
        </div>
        <div v-else-if="project.status === 4 && client.status === 2">
          <div class="alert alert-warning mt-4">
            Este projeto foi encerrado.
            <div v-if="assistant.plan !== 'Free' && assistant.status !== 1 && project.released_contacts > 0">
              <strong>{{ client.name }}</strong> se interessou por
              {{
                formatPlural(project.released_contacts, "proposta", "propostas")
              }}
              neste projeto e pode ter contratado alguém.
            </div>
            <div v-else-if="assistant.plan !== 'Free' && assistant.status !== 1">
              <strong>{{ client.name }}</strong> não se interessou por nenhuma proposta neste projeto.
            </div>
          </div>
        </div>

        <div class="row mt-4" style="margin:0" v-if="!proposal">
          <div class="tab">
            <h1>Sua proposta para este projeto</h1>
          </div>
          <div class="col-sm-12 section" style="padding-left: 0">
            <div class="create-project" style="margin-top: 20px">
              <div class="card" style="max-width: 500px;">
                <img src="/assets/img/Send.svg" style="width:50px" />
                <div class="card-body" style="margin-top: 0px">
                  <h2 class="light mb-3">
                    Você ainda não enviou uma proposta para este projeto
                  </h2>
                  <a class="btn" @click="sendProposal()" :class="{ 'disabled': activeUser.status !== 2 }">Enviar
                    proposta</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <Proposal :assistant="assistant" :client="client" :project="project" :proposal="proposal" />
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import Proposal from "../project/Proposal.vue";
import AssistantManager from "../../../js/services/assistant.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar, Proposal },

  created() {
    this.$store.commit("setActiveSection", "ProjectPage");
    this.loadProject();
  },

  data() {
    return {
      assistant: null,
      project: null,
      client: null,
      proposal: null,
      monthlyProposals: null
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
    activeUser() {
      return this.$store.state.activeUser
    }
  },

  methods: {
    async loadProject() {
      this.$store.commit("setLoading", true);

      try {
        let response = await AssistantManager.getProject(
          this.$route.params.projectId
        );
        let profile = response.assistant;
        this.assistant = profile;
        this.project = response.project;
        this.client = response.client;
        this.proposal = response.proposal;
        this.monthlyProposals = response.monthlyProposals;
        this.$store.commit("setActiveUser", profile);
        this.$store.commit("setLoading", false);
        let ctx = this;
        this.applyTruncation(() => {
          ctx.initTooltips();
        });
      } catch (error) {
        this.handleAPIError(error, "projeto/" + this.$route.params.projectId);
      }
    },

    sendProposal() {
      let $ctx = this;

      if (this.activeUser.status === 1) {
        this.showError(
          "Seu perfil ainda está sendo analisado.\n\nAssim que for aprovado pela nossa equipe, você poderá postar propostas."
        );
        return;
      }

      let $modal = $("#sendProposalModal");
      let $description = $modal.find("[data-proposal-description]");

      $modal.find(".item").removeClass("error");


      if (this.activeUser.plan === 'Free') {
        let left = Math.max(0, this.monthlyProposals.limit - this.monthlyProposals.current);

        if (left > 0) {
          $modal.find('[data-left-proposals]').show();
          $modal.find('[data-proposal-end-date]').text(this.monthlyProposals.endDate);
          $modal.find('[data-proposal-count]').text(left);
          $modal.find('[data-proposal-text]').text(left === 1 ? 'proposta' : 'propostas');
        } else {
          $ctx.showSubscribeModal('Ops! Você atingiu o limite de propostas para este mês.', 'Assine a Virtap para ter acesso a propostas ilimitadas, aulas exclusivas e outros benefícios.');
          return;
        }
      } else {
        $modal.find('[data-left-proposals]').hide();
      }

      $description.val("");
      $description.off().on('input', function () {
        $description.css('height', 'auto');
        $description.css('height', Math.max($description[0].scrollHeight, 200) + "px");
      });


      let action = async function () {
        let proposalDetails = {
          project_id: $ctx.project.id,
          description: $description.val(),
        };


        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (proposalDetails.description.length < 50) {
          $description.closest(".item").addClass("error");
          $description.closest('.item').find('em').text('Por favor, informe uma proposta com pelo menos 50 caracteres.');
          hasError = true;
        } else if ($ctx.hasContactInfo(proposalDetails.description)) {
          $description.closest('.item').addClass('error');
          $description.closest('.item').find('em').text('Por favor, não informe dados de contato ou links na sua proposta.');
          hasError = true;
        }

        let $chk = $modal.find('[data-chk-terms]');
        if (!$chk.prop('checked')) {
          $chk
            .closest(".item")
            .addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        let response = await AssistantManager.sendProposal(proposalDetails);
        $ctx.proposal = response.proposal;
        if ($ctx.monthlyProposals) {
          $ctx.monthlyProposals.current++;
        }
        $ctx.applyTruncation();

        return true;
      };

      this.showModal(
        $modal,
        function () {
          $description.trigger('input');
          $modal.find("[data-proposal-description]").focus();
        },
        "Enviar",
        action,
        "Proposta enviada.",
        "Ocorreu um erro ao enviar sua proposta.\nPor favor, tente novamente. Se o problema persistir, atualize a página.",
        function () {
          $description.val("");
          $description.css('height', '200px');
        }
      );
    },
  },
};
</script>
