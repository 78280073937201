<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="center-block" v-if="course === null">
          <div>
            <div class="alert alert-danger mb-0">Este curso não está disponível.</div>
          </div>
        </div>
        <div class="client-page" v-else>
          <div class="alert alert-warning mb-3" v-if="profile.status === 1">
            Este curso ficará disponível assim que seu perfil for aprovado.
          </div>
          <h1 class="mb-3">{{ course.title }}</h1>
          <div style="margin-right: 0">
            <p>
              O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O
              Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde
              o ano de 1500, quando uma misturou os caracteres de um texto para criar um
              espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o
              salto para a tipografia electrónica, mantendo-se essencialmente inalterada.
              Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset,
              que continham passagens com Lorem Ipsum, e mais recentemente com os
              programas de publicação como o Aldus PageMaker que incluem versões do Lorem
              Ipsum.
            </p>

            <p class="mt-3 mb-3">
              O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O
              Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde
              o ano de 1500, quando uma misturou os caracteres de um texto para criar um
              espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o
              salto para a tipografia electrónica, mantendo-se essencialmente inalterada.
              Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset,
              que continham passagens com Lorem Ipsum, e mais recentemente com os
              programas de publicação como o Aldus PageMaker que incluem versões do Lorem
              Ipsum.
            </p>
            <ul class="list text-muted" v-for="module in course.modules" :key="module.id">
              <li>
                <strong>{{ module.title }}</strong>
                <ul class="list text-muted">
                  <li v-for="classObj in module.classes" :key="classObj.id">
                    {{ classObj.title }}
                  </li>
                </ul>
              </li>
            </ul>

            <p class="mt-3 text-center">
              <button
                class="btn"
                @click="goToCourse()"
                :class="{ disabled: profile.status !== 2 }"
              >
                <i class="fa fa-graduation-cap" /> Ir para o curso
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../../SideBar.vue";
import TopBar from "../../TopBar.vue";
import AssistantManager from "../../../../js/services/assistant.js";
import StringUtils from "../../../../js/utils/string.js";
import Config from "../../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "CoursePage");
    this.loadCourse();
  },

  data() {
    return {
      profile: null,
      course: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadCourse() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AssistantManager.getCourse(this.$route.path.substring(7));
        this.course = response.course;
        this.profile = response.assistant;
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.handleAPIError(error, `curso/${this.$route.path.substring(7)}`);
      }
    },
    async goToCourse() {
      if (this.profile.status === 1) {
        this.showError("Este curso ficará disponível assim que seu perfil for aprovado!");
        return;
      } else {
        location.href = `/curso/${this.$route.path.substring(7)}/${
          this.course.lastSeenClassId
        }`;
      }
    },
  },
};
</script>
