<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body --> 
      <div class="container-fluid body-container">
        <section class="video_play_page" v-if="video">
          <h1 class="video-title">{{ video.title }}</h1>
          <div class="row">
            <div class="col-lg-12" v-if="freePlan || activeUser.status === 1">
              <div class="video-container">
                <div class="banner">Disponível apenas para assinantes</div>
              </div>
            </div>
            <div v-else>
              <div class="video-container" v-if="video.type === 1">
                <video-js
                  id="player"
                  controls
                  controlsList="nodownload"
                  fluid="true"
                  class="video-js vjs-theme-city vjs-big-play-centered"
                >
                  <source
                    :src="getVideoURL(video)"
                    type="application/x-mpegURL"
                    data-setup='{ "playbackRates": [0.5, 1, 1.5, 2] }'
                  />
                </video-js>
              </div>
              <div
                class="iframe-embed-wrapper iframe-embed-responsive-16by9"
                v-else-if="video.type === 2"
              >
                <iframe
                  class="iframe-embed"
                  width="100%"
                  height="auto"
                  title="YouTube video player"
                  frameborder="0"
                  loading="lazy"
                  :src="video.path"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <div class="col-lg-12" v-if="freePlan || activeUser.status === 1">
              <div class="video-details">
                <div
                  class="d-flex mb-2"
                  style="
                    font-size: 14.2px;
                    gap: 20px;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <span class="fw-bold">{{
                    formatPlural(video.views, "visualização", "visualizações")
                  }}</span>
                  <span class="fw-bold">{{
                    formatElapsedTime(video.registration_timestamp)
                  }}</span>
                  <div
                    class="d-flex btn btn-small"
                    style="align-items: center; cursor: default; background: none"
                  >
                    <span
                      >{{ video.likes }}
                      <i
                        class="fa-regular fa-thumbs-up"
                        style="font-size: 20px; margin-top: -4.8px"
                      ></i
                    ></span>
                  </div>
                </div>
                <div class="wrap-box short">
                  <p class="break-word" style="font-size: 14.2px">
                    {{ video.description }}
                  </p>
                  <label></label>
                </div>
              </div>
            </div>
            <div class="col-lg-12" v-else>
              <div class="video-details">
                <div
                  class="d-flex mb-2"
                  style="
                    font-size: 14.2px;
                    gap: 20px;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <span class="fw-bold">{{
                    formatPlural(video.views, "visualização", "visualizações")
                  }}</span>
                  <span class="fw-bold">{{
                    formatElapsedTime(video.registration_timestamp)
                  }}</span>
                  <div
                    class="d-flex btn btn-small liked"
                    style="align-items: center; cursor: pointer"
                    v-if="video.liked"
                    @click="unlikeVideo()"
                  >
                    <span
                      >{{ video.likes }}
                      <i
                        class="fa-solid fa-thumbs-up"
                        style="font-size: 20px; margin-top: -4.8px"
                      ></i
                    ></span>
                  </div>
                  <div
                    class="d-flex btn btn-small"
                    style="align-items: center; cursor: pointer"
                    v-else
                    @click="likeVideo()"
                  >
                    <span
                      >{{ video.likes }}
                      <i
                        class="fa-regular fa-thumbs-up"
                        style="font-size: 20px; margin-top: -4.8px"
                      ></i
                    ></span>
                  </div>
                </div>
                <div class="wrap-box short">
                  <p class="break-word" style="font-size: 14.2px">
                    {{ video.description }}
                  </p>
                  <label></label>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-3" v-if="video.type === 1">
              <div class="comments-section" v-if="!freePlan && activeUser.status === 2">
                <h3 class="comments-title">Adicione um comentário</h3>
                <form id="comment-form" class="comment-form item" onsubmit="return false">
                  <textarea
                    class="form-control"
                    id="txtComment"
                    style="height: 100px; font-size: 14.2px"
                    :disabled="posting"
                    :class="{ disabled: posting }"
                  ></textarea>
                  <em style="display: none; font-size: 12px" class="mt-3 mb-2"></em>
                  <p
                    class="text-danger mt-1"
                    style="display: none; font-size: 14px"
                    data-error
                  ></p>
                  <button
                    type="submit"
                    class="btn btn-medium mt-2 mb-4"
                    @click="addComment()"
                    :disabled="posting"
                    :class="{ disabled: posting }"
                  >
                    {{ posting ? "Por favor, aguarde..." : "Enviar" }}
                  </button>
                </form>
              </div>
              <div v-else></div>
              <h3 class="comments-title mb-0">Comentários ({{ video.active_comments }})</h3>
              <hr
                style="margin-bottom: 0px; border-color: var(--border-color); opacity: 1"
                v-if="comments.length > 0"
              />
              <div v-if="!freePlan && activeUser.status === 2">
                <div class="alert alert-warning mt-2" v-if="comments.length === 0">
                  Nenhum comentário sobre esse vídeo ainda.
                </div>
                <div class="comments" v-else>
                  <div
                    class="box"
                    v-for="comment in comments"
                    :key="comment.id"
                    :id="comment.id"
                  >
                    <img :src="getImageURL(comment.picture)" v-if="comment.picture" />
                    <img class="empty-image" v-else />
                    <div class="c_text_area">
                      <p class="name">
                        {{ comment.name ? comment.name : "Virtap"
                        }}<span>{{
                          formatElapsedTime(comment.registration_timestamp)
                        }}</span>
                      </p>
                      <div class="wrap-box short">
                        <p
                          class="break-word dsc"
                          v-html="formatMultilineText(comment.comment)"
                        ></p>
                        <label></label>
                      </div>
                      <p class="under-review" v-if="comment.status === 1">
                        Seu comentário está aguardando moderação e encontra-se invisível
                        no momento.
                      </p>
                      <p class="inactive" v-else-if="comment.status === 3">
                        Seu comentário foi desativado.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p v-else class="mt-0 text-muted" style="font-size: 12pt; font-style: italic">
                Apenas assinantes podem ver e postar comentários.
              </p>
            </div>
          </div>

          <div class="page_number" v-if="video.type === 1">
            <button
              id="fetchMoreComments"
              class="btn see_more btn-small"
              :class="{ disabled: loadingMore }"
              @click="fetchMore()"
              v-if="hasMore"
            >
              {{ loadingMore ? "Carregando..." : "Mostrar mais" }}
            </button>
          </div>
        </section>
        <div class="center-block" v-else>
          <div>
            <div class="alert alert-danger mb-0">
              Este vídeo não está disponível.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AssistantManager from "../../../js/services/assistant.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

const RESULT_COUNT = 10;

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "VideoPage");
    this.loadVideo();
  },

  data() {
    return {
      video: null,
      posting: false,
      comments: [],
      loadingMore: false,
      hasMore: true,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
    freePlan() {
      return this.activeUser.plan === "Free";
    },
  },

  methods: {
    async loadVideo() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AssistantManager.getVideo(
          this.$route.params.videoId,
          RESULT_COUNT
        );
        this.video = response.video;
        this.comments = response.comments;
        this.hasMore = this.comments.length >= RESULT_COUNT;
        this.$store.commit("setActiveUser", response.assistant);
        this.$store.commit("setLoading", false);
        this.applyTruncation();
        if (this.activeUser.status === 1 || this.activeUser.plan === 'Free') {
          this.showSubscribeModal('Conteúdo exclusivo para assinantes!', 'Assine a Virtap para ter acesso a propostas ilimitadas, aulas exclusivas e outros benefícios.');
        }

        if (!this.video || this.video.type !== 1 || this.activeUser.status !== 2 || this.activeUser.plan === 'Free') {
          return;
        }
        this.$nextTick(() => {
          let player = videojs("player", {
            playbackRates: [0.5, 1, 1.5, 2],
            html5: {
              hls: {
                withCredentials: true,
              },
            },
          });
          player.play();
        });
      } catch (error) {
          this.handleAPIError(error, "video/" + this.$route.params.videoId);
      }
    },

    async addComment() {
      let $ctx = this;
      let $item = $("#txtComment").closest(".item");

      this.posting = true;

      try {
        let commentDetails = {
          video_id: this.video.id,
          comment: $("#txtComment").val(),
        };

        $item.removeClass("error");
        $item.find("em").hide();
        $item.find("[data-error]").hide();

        if (
          StringUtils.isEmpty(commentDetails.comment) ||
          commentDetails.comment.length < 10
        ) {
          $item.addClass("error");
          $item
            .find("em")
            .text("Por favor, informe um cometário com pelo menos 10 caracteres.")
            .show();
          $("#txtComment").focus();
          return;
        }

        let response = await AssistantManager.addComment(commentDetails);
        this.comments.unshift(response.comment);
        $("#txtComment").val("");
      } catch (error) {
        console.log(error);
        if (error.status === 401) {
          $item.find("[data-error]").html($ctx.buildSessionLink()).show();
        } else {
          $item
            .find("[data-error]")
            .html(
              $ctx.formatMultilineText(
                "Ocorreu um erro postar o comentário.\nPor favor, tente novamente. Se o problema persistir, atualize a página."
              )
            )
            .show();
        }
      } finally {
        this.posting = false;
      }
    },

    async fetchMore() {
      let ctx = this;
      ctx.loadingMore = true;
      let lastComment = null;
      if (this.comments && this.comments.length > 0) {
        lastComment = this.comments[this.comments.length - 1];
      }
      try {
        let response = await AssistantManager.getComments(
          ctx.video.id,
          lastComment,
          RESULT_COUNT
        );

        this.comments = this.comments.concat(response.comments);
        if (response.comments.length < RESULT_COUNT) {
          this.hasMore = false;
        }

        ctx.$nextTick(() => {
          ctx.applyTruncation(() => {
            if (response.comments.length > 0) {
              let card = $(".comments")
                .find("#" + response.comments[0].id)
                .get(0);
              let scrollDiv = card.offsetTop - 10;
              window.scrollTo({ top: scrollDiv, behavior: "smooth" });
            }
          });
        });
      } catch (err) {
        console.log(err);
      } finally {
        ctx.loadingMore = false;
      }
    },

    async likeVideo() {
      try {
        this.video.likes++;
        this.video.liked = true;
        await AssistantManager.likeVideo(this.video.id);
      } catch (err) {
        console.log(err);
      }
    },

    async unlikeVideo() {
      try {
        this.video.likes--;
        this.video.liked = false;
        await AssistantManager.unlikeVideo(this.video.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
