<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div v-if="course !== null && course.config !== null">
          <div class="center-block" v-if="classObj === null">
            <div>
              <div class="alert alert-danger mb-0">
                A aula que você tentou acessar não está disponível.
              </div>
            </div>
          </div>
          <section class="video_play_page" v-else>
            <div style="width: 66.66666667%">
              <h1
                class="video-title mb-2"
                style="width: auto; max-width: 960px; text-align: left"
              >
                {{ course.title }}<br />
                <span class="text-muted">{{ classObj.title }}</span>
              </h1>
            </div>
            <div class="row gy-2 mt-0">
              <div class="col-lg-8">
                <div
                  class="video-container"
                  v-if="
                    this.classObj.type === 'video' &&
                    this.classObj.config.hosting === 'R2'
                  "
                >
                  <video-js
                    id="player"
                    controls
                    controlsList="nodownload"
                    fluid="true"
                    class="video-js vjs-theme-city vjs-big-play-centered"
                  >
                    <source
                      src="http://sample.vodobox.net/skate_phantom_flex_4k/skate_phantom_flex_4k.m3u8"
                      :x-src="getCourseClassURL(this.course, this.module, this.classObj)"
                      type="application/x-mpegURL"
                      data-setup='{ "playbackRates": [0.5, 1, 1.5, 2] }'
                    />
                  </video-js>
                </div>

                <div
                  class="iframe-embed-wrapper iframe-embed-responsive-16by9"
                  v-else-if="
                    this.classObj.type === 'video' &&
                    this.classObj.config.hosting === 'youtube'
                  "
                >
                  <iframe
                    id="iframe-embed"
                    class="iframe-embed"
                    width="100%"
                    height="auto"
                    title="YouTube video player"
                    frameborder="0"
                    loading="lazy"
                    :src="this.classObj.config.path + '?version=3&enablejsapi=1'"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="accordion accordion-flush" id="modules" :key="course.id">
                  <div
                    class="accordion-item"
                    v-for="module in course.modules"
                    :key="course.id + '_module_' + module.id"
                  >
                    <h2 class="accordion-header" :id="'module_' + module.id">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#flush-collapse' + module.id"
                        aria-expanded="false"
                        :aria-controls="'#flush-collapse' + module.id"
                      >
                        <span class="d-flex">
                          <span
                            >{{ module.title }}
                            <span class="text-muted">({{ module.progress }}%)</span></span
                          >
                        </span>
                      </button>
                    </h2>
                    <div
                      :id="'flush-collapse' + module.id"
                      class="accordion-collapse"
                      :class="{
                        show: classObj.course_module_id === module.id,
                        collapse: classObj.course_module_id !== module.id,
                      }"
                      :aria-labelledby="'module_' + module.id"
                      data-bs-parent="#modules"
                    >
                      <div class="accordion-body">
                        <p
                          class="d-flex nav-link"
                          :class="{ active: c.id === classObj.id }"
                          v-for="c in module.classes"
                          :key="course.id + '_class_' + c.id"
                          @click="goToClass(c)"
                        >
                          <span v-if="c.completed">
                            <i class="fa-solid fa-check-circle complete"></i>
                          </span>
                          <span v-else>
                            <i class="fa-regular fa-circle"></i>
                          </span>
                          <span>{{ c.title }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-0">
              <div class="video-details mt-3">
                <div
                  class="d-flex mb-2"
                  style="
                    font-size: 14.2px;
                    gap: 20px;
                    flex-wrap: wrap;
                    align-items: center;
                  "
                >
                  <span class="fw-bold">{{
                    formatPlural(classObj.views, "visualização", "visualizações")
                  }}</span>
                  <div
                    class="d-flex btn btn-small liked"
                    style="align-items: center; cursor: pointer"
                    v-if="classObj.liked"
                    @click="unlikeClass()"
                  >
                    <span
                      >{{ classObj.likes }}
                      <i
                        class="fa-solid fa-thumbs-up"
                        style="font-size: 20px; margin-top: -4.8px"
                      ></i
                    ></span>
                  </div>
                  <div
                    class="d-flex btn btn-small"
                    style="align-items: center; cursor: pointer"
                    v-else
                    @click="likeClass()"
                  >
                    <span
                      >{{ classObj.likes }}
                      <i
                        class="fa-regular fa-thumbs-up"
                        style="font-size: 20px; margin-top: -4.8px"
                      ></i
                    ></span>
                  </div>
                </div>
                <div class="wrap-box short">
                  <p class="break-word" style="font-size: 14.2px">
                    {{ classObj.description }}
                  </p>
                  <label></label>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="comments-section" v-if="!freePlan">
                <h3 class="comments-title">Adicione um comentário</h3>
                <form id="comment-form" class="comment-form item" onsubmit="return false">
                  <textarea
                    class="form-control"
                    id="txtComment"
                    style="height: 100px; font-size: 14.2px"
                    :disabled="posting"
                    :class="{ disabled: posting }"
                  ></textarea>
                  <em style="display: none; font-size: 12px" class="mt-3 mb-2"></em>
                  <p
                    class="text-danger mt-1"
                    style="display: none; font-size: 14px"
                    data-error
                  ></p>
                  <button
                    type="submit"
                    class="btn btn-medium mt-2 mb-4"
                    @click="addComment()"
                    :disabled="posting"
                    :class="{ disabled: posting }"
                  >
                    {{ posting ? "Por favor, aguarde..." : "Enviar" }}
                  </button>
                </form>
              </div>
              <div v-else></div>
              <h3 class="comments-title">Comentários ({{ classObj.active_comments }})</h3>
              <hr
                style="margin-bottom: 0px; border-color: var(--border-color); opacity: 1"
                v-if="comments.length > 0"
              />
              <div class="alert alert-warning mt-2" v-if="comments.length === 0">
                Nenhum comentário sobre essa aula ainda.
              </div>
              <div class="comments" v-else>
                <div
                  class="box"
                  v-for="comment in comments"
                  :key="comment.id"
                  :id="comment.id"
                >
                  <img :src="getImageURL(comment.picture)" v-if="comment.picture" />
                  <img class="empty-image" v-else />
                  <div class="c_text_area" style="flex-grow: 1">
                    <p class="name">
                      {{ comment.name ? comment.name : "Virtap"
                      }}<span>{{
                        formatElapsedTime(comment.registration_timestamp)
                      }}</span>
                    </p>
                    <div class="wrap-box short">
                      <p
                        class="break-word dsc"
                        v-html="formatMultilineText(comment.comment)"
                      ></p>
                      <label></label>
                    </div>
                    <p class="under-review" v-if="comment.status === 1">
                      Seu comentário está aguardando moderação e encontra-se invisível no
                      momento.
                    </p>
                    <p class="inactive" v-else-if="comment.status === 3">
                      Seu comentário foi desativado.
                    </p>
                    <p
                      class="mt-2"
                      v-if="!comment.replyShown && comment.children_count > 0"
                    >
                      <button
                        class="btn btn-small bg-secondary"
                        :class="{ disabled: comment.showingReply }"
                        @click="showReply(comment)"
                      >
                        {{ comment.showingReply ? "Carregando..." : "Ver resposta" }}
                      </button>
                    </p>
                    <div v-if="comment.replyShown">
                      <hr style="width: 100%; color: #aaa" />
                      <p class="dsc break-line" style="color: var(--secondary-color)">
                        {{ comment.reply }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page_number">
              <button
                id="fetchMoreComments"
                class="btn see_more btn-small"
                :class="{ disabled: loadingMore }"
                @click="fetchMore()"
                v-if="hasMore"
              >
                {{ loadingMore ? "Carregando..." : "Mostrar mais" }}
              </button>
            </div>
          </section>
        </div>
        <div class="center-block" v-else>
          <div>
            <div class="alert alert-danger mb-0">Este curso não está disponível.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AssistantManager from "../../../js/services/assistant.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

const RESULT_COUNT = 2;

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "ClassPage");
    this.loadClass();
  },

  data() {
    return {
      course: null,
      module: null,
      classObj: null,
      posting: false,
      comments: [],
      loadingMore: false,
      hasMore: true,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
    freePlan() {
      return this.activeUser.plan === "Free";
    },
  },

  methods: {
    async loadClass() {
      let $ctx = this;
      this.$store.commit("setLoading", true);
      try {
        let response = await AssistantManager.getClass(
          this.$route.params.courseId,
          this.$route.params.classId,
          RESULT_COUNT
        );
        response.comments.forEach((c) => {
          c.replyShown = false;
          c.showingReply = false;
          c.reply = null;
        });
        this.course = response.course;
        this.module = response.module;
        this.classObj = response.class;
        this.comments = response.comments;
        this.hasMore = this.comments.length >= RESULT_COUNT;
        this.updateStats();
        this.$store.commit("setActiveUser", response.assistant);
        this.$store.commit("setLoading", false);
        this.applyTruncation();
        if (!this.course || !this.course.config || this.activeUser.status !== 2) {
          return;
        }
        this.$nextTick(() => {
          if (this.classObj.type === "video") {
            if (this.classObj.config.hosting === "R2") {
              let player = videojs("player", {
                playbackRates: [0.5, 1, 1.5, 2],
                html5: {
                  hls: {
                    // withCredentials: true,
                  },
                },
              });

              player.ready(function () {
                player.on("ended", async function () {
                  try {
                    let allCompleted = await AssistantManager.completeClass(
                      $ctx.course.id,
                      $ctx.classObj.id
                    );
                    $ctx.classObj.completed = true;
                    for (let m of $ctx.course.modules) {
                      for (let c of m.classes) {
                        if (c.id === $ctx.classObj.id) {
                          c.completed = true;
                        }
                      }
                    }
                    $ctx.updateStats();

                    if (allCompleted && $ctx.course.id === "form-av") {
                      $ctx.showSuccess(
                        "Parabéns! Você completou o curso " + $ctx.course.title + "!"
                      );
                    }
                  } catch (err) {
                    console.log("Failure marking class as viewed", $ctx.classObj, err);
                  }
                });

                player.play();
              });
            } else if (this.classObj.config.hosting === "youtube") {
              let player;
              window.onYouTubeIframeAPIReady = function () {
                player = new YT.Player("iframe-embed", {
                  events: {
                    onReady: function (event) {
                      player.playVideo();
                    },
                    onStateChange: async function (event) {
                      if (event.data === 0) {
                        try {
                          let allCompleted = await AssistantManager.completeClass(
                            $ctx.course.id,
                            $ctx.classObj.id
                          );
                          $ctx.classObj.completed = true;
                          for (let m of $ctx.course.modules) {
                            for (let c of m.classes) {
                              if (c.id === $ctx.classObj.id) {
                                c.completed = true;
                              }
                            }
                          }
                          $ctx.updateStats();

                          if (allCompleted && $ctx.course.id === "form-av") {
                            $ctx.showSuccess(
                              "Parabéns! Você completou o curso " +
                                $ctx.course.title +
                                "!"
                            );
                          }
                        } catch (err) {
                          console.log(
                            "Failure marking class as viewed",
                            $ctx.classObj,
                            err
                          );
                        }
                      }
                    },
                  },
                });
              };

              var tag = document.createElement("script");
              tag.src = "https://www.youtube.com/iframe_api";
              var firstScriptTag = document.getElementsByTagName("script")[0];
              firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }
          }
        });
      } catch (error) {
        this.handleAPIError(
          error,
          "curso/" + this.$route.params.courseId + "/" + this.$route.params.classId
        );
      }
    },
    updateStats() {
      if (this.course) {
        for (let m of this.course.modules) {
          let s = 0;
          for (let c of m.classes) {
            if (c.completed) {
              s++;
            }
          }
          m.progress = Number((s / m.classes.length) * 100.0).toFixed(0);
        }
      }
    },

    goToClass(classObj) {
      location.href = "/curso/" + this.course.id + "/" + classObj.id;
    },

    async addComment() {
      let $ctx = this;
      let $item = $("#txtComment").closest(".item");

      this.posting = true;

      try {
        let commentDetails = {
          class_id: this.classObj.id,
          comment: $("#txtComment").val(),
        };

        $item.removeClass("error");
        $item.find("em").hide();
        $item.find("[data-error]").hide();

        if (
          StringUtils.isEmpty(commentDetails.comment) ||
          commentDetails.comment.length < 10
        ) {
          $item.addClass("error");
          $item
            .find("em")
            .text("Por favor, informe um cometário com pelo menos 10 caracteres.")
            .show();
          $("#txtComment").focus();
          return;
        }

        let response = await AssistantManager.addClassComment(
          this.course.id,
          commentDetails
        );
        this.comments.unshift(response.comment);
        $("#txtComment").val("");
      } catch (error) {
        console.log(error);
        if (error.status === 401) {
          $item.find("[data-error]").html($ctx.buildSessionLink()).show();
        } else {
          $item
            .find("[data-error]")
            .html(
              $ctx.formatMultilineText(
                "Ocorreu um erro postar o comentário.\nPor favor, tente novamente. Se o problema persistir, atualize a página."
              )
            )
            .show();
        }
      } finally {
        this.posting = false;
      }
    },

    async showReply(comment) {
      try {
        comment.showingReply = true;
        let response = await AssistantManager.getClassCommentReply(
          this.course.id,
          this.classObj.id,
          comment.id
        );
        if (response.comments.length > 0) {
          comment.reply = response.comments[0].comment;
          comment.replyShown = true;
        }
      } finally {
        comment.showingReply = false;
      }
    },

    async fetchMore() {
      let ctx = this;
      ctx.loadingMore = true;
      let lastComment = null;
      if (this.comments && this.comments.length > 0) {
        lastComment = this.comments[this.comments.length - 1];
      }
      try {
        let response = await AssistantManager.getClassComments(
          ctx.course.id,
          ctx.classObj.id,
          lastComment,
          RESULT_COUNT
        );

        this.comments = this.comments.concat(response.comments);
        if (response.comments.length < RESULT_COUNT) {
          this.hasMore = false;
        }

        ctx.$nextTick(() => {
          ctx.applyTruncation(() => {
            if (response.comments.length > 0) {
              let card = $(".comments")
                .find("#" + response.comments[0].id)
                .get(0);
              let scrollDiv = card.offsetTop - 10;
              window.scrollTo({ top: scrollDiv, behavior: "smooth" });
            }
          });
        });
      } catch (err) {
        console.log(err);
      } finally {
        ctx.loadingMore = false;
      }
    },

    async likeClass() {
      try {
        this.classObj.likes++;
        this.classObj.liked = true;
        await AssistantManager.likeClass(this.course.id, this.classObj.id);
      } catch (err) {
        console.log(err);
      }
    },

    async unlikeClass() {
      try {
        this.classObj.likes--;
        this.classObj.liked = false;
        await AssistantManager.unlikeClass(this.course.id, this.classObj.id);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
