<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="center-block" v-if="course === null">
          <div>
            <div class="alert alert-danger mb-0">Este curso não está disponível.</div>
          </div>
        </div>

        <div class="client-page" v-else>
          <div
            class="alert alert-info mb-3 text-center"
            v-if="profile.status === 1 || profile.plan !== 'Vip'"
          >
            Este curso está disponível apenas para assinantes do plano
            <strong>Vip</strong>.
            <div class="admin-buttons-h mt-3">
              <a class="btn" href="/plano"
                ><i class="fa fa-plus" /> Veja todas as vantagens</a
              >
            </div>
          </div>

          <h1 class="mb-2">{{ course.title }}</h1>
          <div style="margin-right: 0">
            <p>
              O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O
              Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde
              o ano de 1500, quando uma misturou os caracteres de um texto para criar um
              espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o
              salto para a tipografia electrónica, mantendo-se essencialmente inalterada.
              Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset,
              que continham passagens com Lorem Ipsum, e mais recentemente com os
              programas de publicação como o Aldus PageMaker que incluem versões do Lorem
              Ipsum.
            </p>

            <p class="mt-3 mb-3">
              O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O
              Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde
              o ano de 1500, quando uma misturou os caracteres de um texto para criar um
              espécime de livro. Este texto não só sobreviveu 5 séculos, mas também o
              salto para a tipografia electrónica, mantendo-se essencialmente inalterada.
              Foi popularizada nos anos 60 com a disponibilização das folhas de Letraset,
              que continham passagens com Lorem Ipsum, e mais recentemente com os
              programas de publicação como o Aldus PageMaker que incluem versões do Lorem
              Ipsum.
            </p>
            <ul class="list text-muted" v-for="module in course.modules" :key="module.id">
              <li>
                <strong>{{ module.title }}</strong>
                <ul class="list text-muted">
                  <li v-for="classObj in module.classes" :key="classObj.id">
                    {{ classObj.title }}
                  </li>
                </ul>
              </li>
            </ul>

            <p class="mt-4 fw-bold fs-6">Certificado incluso neste curso:</p>
            <div class="certav-box mt-2">
              <strong>Profissional certificado em Assistência Virtual</strong>
              <div class="cert-container">
                <i class="fa fa-laptop cert_av"></i>
                <div>
                  <div class="break-word">
                    Treinamento completo em Assistência Virtual fornecido pela Virtap.
                  </div>

                  <div class="break-word">
                    Elaborado por profissionais que acumulam 10 anos de experiência no
                    mercado.
                  </div>
                </div>
              </div>
            </div>

            <div class="alert alert-success mt-3" v-if="profile.cert_av">
              <div class="text-center">
                <strong
                  >Parabéns! Você completou este curso e assumiu uma posição destacada na
                  plataforma!
                </strong>
              </div>
              <ul class="list mt-3">
                <li>
                  Seu certificado de conclusão está sendo exibido no seu perfil e nas suas
                  propostas enviadas.
                </li>

                <li>
                  Seu perfil e suas propostas enviadas estão recebendo mais destaque na
                  plataforma.
                </li>
              </ul>
            </div>

            <div class="alert alert-warning mt-3" v-else>
              <div class="text-center">
                <strong
                  >Conclua este curso e assuma uma posição destacada na plataforma!
                </strong>
              </div>
              <ul class="list mt-3">
                <li>
                  Após concluir o curso, seu certificado de conclusão será exibido no seu
                  perfil e nas suas propostas enviadas.
                </li>

                <li>
                  Após concluir o curso, seu perfil e suas propostas receberão mais
                  destaque na plataforma.
                </li>
              </ul>
            </div>

            <p class="mt-3 text-center">
              <button
                class="btn"
                @click="goToCourse()"
                v-if="profile.status === 2 && profile.plan === 'Vip'"
              >
                <i class="fa fa-graduation-cap" /> Ir para o curso
              </button>

              <a v-else class="btn" href="/plano">
                <i class="fa fa-graduation-cap" /> Quero ter acesso ao curso!
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../../SideBar.vue";
import TopBar from "../../TopBar.vue";
import AssistantManager from "../../../../js/services/assistant.js";
import StringUtils from "../../../../js/utils/string.js";
import Config from "../../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "CoursePage");
    this.loadCourse();
  },

  data() {
    return {
      profile: null,
      course: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadCourse() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AssistantManager.getCourse(this.$route.path.substring(7));
        this.course = response.course;
        this.profile = response.assistant;
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.handleAPIError(error, `curso/${this.$route.path.substring(7)}`);
      }
    },
    async goToCourse() {
      if (this.profile.status === 1 || this.profile.plan !== "Vip") {
        return;
      }
      location.href = `/curso/${this.$route.path.substring(7)}/${
        this.course.lastSeenClassId
      }`;
    },
  },
};
</script>
