<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container" :class="{'cm': profile.status === 2}">

        <div class="center-block" v-if="profile.status === 1">
          <div>
            <div class="alert alert-warning mb-0">
              Seu perfil está sendo analisado pela nossa equipe. Assim que for aprovado, você poderá entrar na comunidade.
            </div>
          </div>
        </div>
  
        <section class="community" style="width:100%; height: 100%" v-else>
          <div class="text">
            <h1>Comunidade</h1>
            <p class="mt-3">
              Interaja com profissionais
              que estão trilhando o
              mesmo caminho que você
            </p>
            <a :href="telegramLink" target="_blank" class="btn mt-4" v-if="telegramLink">
              <span class="d-flex" style="align-items: center">
                <i class="fa-brands fa-telegram icons"
                  style="color: white; margin-right: 8px; font-size: 32px;"></i><span>Entrar
                  agora</span>
              </span>
            </a>

            <a :href="telegramLink" target="_blank" class="btn mt-4" v-else="telegramLink">
              <span class="d-flex" style="align-items: center">
                <i class="fa-solid fa-cart-shopping"
                  style="color: white; margin-right: 8px; font-size: 28px;"></i><span>Assine a Virtap e <br/> para ter acesso</span>
              </span>
            </a>

          </div>
        </section>

      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AssistantManager from "../../../js/services/assistant.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "CommunityPage");
    this.loadCommunity();
  },

  data() {
    return {
      profile: null,
      telegramLink: null
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadCommunity() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AssistantManager.getCommunityLink();
        this.profile = response.assistant;
        this.telegramLink = response.telegramLink;
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.handleAPIError(error, "comunidade");
      }
    },
  },
};
</script>
