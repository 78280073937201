import Config from "../../../resources/config.js"

async function handleResponse(response) {
  if (response.status === 200) {
    let responseObject = await response.json();
    return responseObject.responseData;
  } else if (response.status === 400) {
    throw await response.json();
  } else {
    throw response;
  }
}

export default {

  async getAssistantData() {
    let url = `${Config.apiHost}/assistant/dashboard`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getProfile() {
    let url = `${Config.apiHost}/assistant/get-profile`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getPlan() {
    let url = `${Config.apiHost}/assistant/get-plan`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getNotifications(lastNotificationId) {
    let url = `${Config.apiHost}/assistant/get-notifications?last_id=${lastNotificationId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async setLastSeenNotifications(lastNotificationId) {
    let url = `${Config.apiHost}/assistant/set-last-seen-notifications?last_id=${lastNotificationId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async setSeenIntroAv() {
    let url = `${Config.apiHost}/assistant/set-seen-intro-av`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async updateProfile(assistantDetails) {
    let url = `${Config.apiHost}/assistant/update-profile`;
    let formData = new FormData();
    let pictureFile = assistantDetails.picture;
    let removePicture = assistantDetails.removePicture;

    delete assistantDetails['picture'];
    delete assistantDetails['removePicture'];

    if (assistantDetails.name) {
      formData.append('name', assistantDetails.name);
    }

    if (assistantDetails.title) {
      formData.append('title', assistantDetails.title);
    }

    if (assistantDetails.location) {
      formData.append('location', assistantDetails.location);
    }

    if (assistantDetails.phone) {
      formData.append('phone', assistantDetails.phone);
    }

    if (assistantDetails.description) {
      formData.append('description', assistantDetails.description);
    }

    if (assistantDetails.expertise_areas) {
      formData.append('expertise_areas', JSON.stringify(assistantDetails.expertise_areas));
    }

    if (pictureFile) {
      formData.append('picture_file', pictureFile);
    }

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getProposals(status) {
    let url = `${Config.apiHost}/assistant/get-proposals?n=100`;

    if (status) {
      url += '&status=' + status
    }
    let response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });

    return await handleResponse(response)
  },

  async getProject(projectId) {
    let url = `${Config.apiHost}/assistant/get-project/${projectId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getClient(clientId) {
    let url = `${Config.apiHost}/assistant/get-client/${clientId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async searchProjects(keywords, includeProfile, lastProject, resultCount) {
    let url = `${Config.apiHost}/assistant/search-projects?n=${resultCount}`;

    if (lastProject) {
      url += '&lv=' + lastProject.expiration_timestamp + "&lp=" + lastProject.position;
    }

    let formData = new FormData();

    if (keywords && keywords.length > 0) {
      formData.append('keywords', keywords);
    }

    formData.append('include_profile', includeProfile);

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async searchVideos(keywords, includeProfile, lastVideo, resultCount) {
    let url = `${Config.apiHost}/assistant/search-videos?n=${resultCount}`;

    if (lastVideo) {
      url += '&lv=' + lastVideo.registration_timestamp + "&lp=" + lastVideo.position;
    }

    let formData = new FormData();

    if (keywords && keywords.length > 0) {
      formData.append('keywords', keywords);
    }

    formData.append('include_profile', includeProfile);

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getCourse(courseId) {
    let url = `${Config.apiHost}/assistant/get-course/${courseId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async searchCourses(keywords, includeProfile, lastCourse, resultCount) {
    let url = `${Config.apiHost}/assistant/search-courses?n=${resultCount}`;

    if (lastCourse) {
      url += '&lv=' + lastCourse.registration_timestamp + "&lp=" + lastCourse.position;
    }

    let formData = new FormData();

    if (keywords && keywords.length > 0) {
      formData.append('keywords', keywords);
    }

    formData.append('include_profile', includeProfile);

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getVideo(videoId, n) {
    let url = `${Config.apiHost}/assistant/get-video/${videoId}?n=${n}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async likeVideo(videoId) {
    let url = `${Config.apiHost}/assistant/like-video/${videoId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async unlikeVideo(videoId) {
    let url = `${Config.apiHost}/assistant/unlike-video/${videoId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async getClass(courseId, classId, n) {
    let url = `${Config.apiHost}/assistant/get-course-class/${courseId}/${classId}?n=${n}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async likeClass(courseId, classId) {
    let url = `${Config.apiHost}/assistant/like-course-class/${courseId}/${classId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async unlikeClass(courseId, classId) {
    let url = `${Config.apiHost}/assistant/unlike-course-class/${courseId}/${classId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async completeClass(courseId, classId) {
    let url = `${Config.apiHost}/assistant/complete-course-class/${courseId}/${classId}`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

  async sendProposal(proposalDetails) {
    let url = `${Config.apiHost}/assistant/send-proposal`;
    let formData = new FormData();

    formData.append('project_id', proposalDetails.project_id);
    formData.append('description', proposalDetails.description);

    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async addComment(commentDetails) {
    let url = `${Config.apiHost}/assistant/add-comment/${commentDetails.video_id}`;
    let formData = new FormData();

    formData.append('comment', commentDetails.comment);
    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getComments(videoId, lastComment, resultCount) {
    let url = `${Config.apiHost}/assistant/get-comments/${videoId}?n=${resultCount}`;

    if (lastComment) {
      url += '&lv=' + lastComment.registration_timestamp + "&lp=" + lastComment.position;
    }

    let response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async addClassComment(courseId, commentDetails) {
    let url = `${Config.apiHost}/assistant/add-class-comment/${courseId}/${commentDetails.class_id}`;
    let formData = new FormData();

    formData.append('comment', commentDetails.comment);
    let response = await fetch(url, {
      method: "POST",
      body: formData,
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getClassComments(courseId, classId, lastComment, resultCount) {
    let url = `${Config.apiHost}/assistant/get-class-comments/${courseId}/${classId}?n=${resultCount}`;

    if (lastComment) {
      url += '&lv=' + lastComment.registration_timestamp + "&lp=" + lastComment.position;
    }

    let response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getClassCommentReply(courseId, classId, commentId) {
    let url = `${Config.apiHost}/assistant/get-class-children-comments/${courseId}/${classId}/${commentId}`;

    let response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });

    return await handleResponse(response);
  },

  async getCommunityLink() {
    let url = `${Config.apiHost}/assistant/get-community-link`;
    let response = await fetch(url, { credentials: "include" });
    return await handleResponse(response);
  },

}