<template>
  <div class="post_project user mt-3" style="height:auto;">
    <div class="proposals-list" :class="{ 'pb-0': hasVideos }" style="height: auto;max-height: 100%">
      <div class="proposal-container" :class="{ nopro: !hasVideos }">
        <div class="no_proposal" v-if="!hasVideos">
          <img src="/assets/img/video.svg" width="100" />
          <h2 class="light">Nenhum vídeo</h2>
        </div>
        <a :href="'/video/' + video.id" v-else v-for="video in videos" :key="video.id">
          <div class="proposal">
            <div class="user">
              <div class="video-img">
                <div class="active-box active"></div>
                <img :src='getVideoThumbnailURL(video)' />

              </div>
              <div class="proposal-info">
                <p class="name ellipsis" v-html="formatMultilineText(video.title)"></p>
                <p class="project ellipsis" v-html="formatMultilineText(video.description)"></p>
                <p class="date ellipsis" v-html="formatMultilineText(formatElapsedTime(video.registration_timestamp))"></p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div v-if="hasVideos" class="mt-3" style="text-align: center">
    <a class="btn_s" href="/video-aulas">Ver todos <i class="fa-solid fa-arrow-right-long"></i></a>
  </div>
</template>

<script>
export default {
  props: ["list"],
  data() {
    return {
      videos: this.list,
    };
  },
  computed: {
    hasVideos() {
      return this.videos && this.videos.length > 0;
    },
  },
  methods: {

  },
};
</script>
