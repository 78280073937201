import DashboardPage from "../../vue/components/pages/DashboardPage.vue";
import ProfilePage from "../../vue/components/pages/ProfilePage.vue";
import PlanPage from "../../vue/components/pages/PlanPage.vue";
import ProposalListPage from "../../vue/components/pages/ProposalListPage.vue";
import ProjectListPage from "../../vue/components/pages/ProjectListPage.vue";
import ProjectPage from "../../vue/components/pages/ProjectPage.vue";
import ClientPage from "../../vue/components/pages/ClientPage.vue";
import VideoListPage from "../../vue/components/pages/VideoListPage.vue";
import VideoPage from "../../vue/components/pages/VideoPage.vue";
import ClassPage from "../../vue/components/pages/ClassPage.vue";
import CourseListPage from "../../vue/components/pages/CourseListPage.vue";
import CourseNotFoundPage from "../../vue/components/pages/CourseNotFoundPage.vue";
import CourseIntroAVPage from "../../vue/components/pages/courses/CourseIntroAVPage.vue";
import CourseFormAVPage from "../../vue/components/pages/courses/CourseFormAVPage.vue";
import CommunityPage from "../../vue/components/pages/CommunityPage.vue";

import NotFoundPage from "../../vue/components/pages/NotFoundPage.vue";
import LoginSuccessPage from "../../vue/components/pages/LoginSuccessPage.vue";
import LoginErrorPage from "../../vue/components/pages/LoginErrorPage.vue";

const routes = [
    { path: ``, component: DashboardPage },
    { path: `/perfil`, component: ProfilePage },
    { path: `/plano`, component: PlanPage },
    { path: `/propostas`, component: ProposalListPage },
    { path: `/projetos`, component: ProjectListPage },
    { path: `/projeto/:projectId`, component: ProjectPage },
    { path: `/cliente/:clientId`, component: ClientPage },
    { path: `/video-aulas`, component: VideoListPage },
    { path: `/video/:videoId`, component: VideoPage },
    { path: `/cursos`, component: CourseListPage }, 
    { path: `/curso/intro-av`, component: CourseIntroAVPage },
    { path: `/curso/form-av`, component: CourseFormAVPage },
    { path: `/curso/:catchAll(.*)`, component: CourseNotFoundPage },
    { path: `/curso/:courseId/:classId`, component: ClassPage },
    // { path: `/ferramentas`, component: ToolListPage },
    { path: `/comunidade`, component: CommunityPage },
    { path: `/login-feito`, component: LoginSuccessPage },
    { path: `/erro-login`, component: LoginErrorPage },
    { path: "/:catchAll(.*)", component: NotFoundPage }
];

export default routes;