<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="element">
          <div class="search-box-container">
            <div class="nav">
              <div>
                <h2>Encontre trabalho</h2>
                <p>Encontre as melhores oportunidades da Assistência Virtual</p>
              </div>
              <div class="search-box">
                <input type="text" id="searchTalentInput" placeholder="Buscar projetos" @input="debounceSearch"
                  :class="{ disabled: searching || loadingMore }" :disabled="searching || loadingMore" />
                <button type="submit" @click="searchProjects()" :class="{ disabled: searching || loadingMore }"
                  :disabled="searching || loadingMore">
                  <i class="fa fa-search icons"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="talent-page" v-if="searching">
            <div class="loading_container" style="height: auto; margin-top: 30px; overflow: hidden">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </div>
          <div class="talent-page" v-else-if="searchError">
            <p class="mt-3 text-center text-danger" style="font-weight: bold">
              Ocorreu um erro ao buscar projetos.<br /> Por favor, atualize a
              página e tente novamente.
            </p>
          </div>
          <div class="talent-page" v-else-if="projects && projects.length === 0">
            <p class="mt-3 text-center" style="font-weight: bold">
              Nenhum projeto encontrado. Tente buscar com outros termos.
            </p>
          </div>
          <div class="talent-page" v-else>
            <div class="">
              <div class="col-lg-12 mt-3 find_work" v-for="project in projects" :key="project.id" :id="project.id">
                <div class="card_group">
                  <div class="card mt-3" style="position: static">
                    <div class="project_info description_part">
                      <div class="project_name">
                        <div class="status">
                          <a :href="'/projeto/' + project.id">
                            <h2 class="break-all">{{ project.title }}</h2>
                          </a>
                          <p style="font-size: 13px;  margin:0; padding:0">
                            Postado por <a :href="'/cliente/' + project.client_id" target="_blank"
                              style="text-decoration: underline;">{{ project.name }}</a>
                          </p>
                          <p style="font-size: 13px; font-style: italic; margin:0; padding:0">

                            {{ formatElapsedTime(new Date(project.registration_timestamp)) }}
                          </p>
                        </div>
                        <div class="work_right_item">
                          <p class="sent_active" :class="{ 'active': project.assistant_id !== null }">
                            Você enviou uma proposta para este projeto
                          </p>
                          <a :href="'/projeto/' + project.id" class="btn">Ver projeto</a>
                        </div>
                      </div>
                      <div class="wrap-box mt-3">
                        <p class="break-word" v-html="formatMultilineText(project.description)">
                        </p>
                        <label></label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="page_number">
              <button id="fetchMoreProjects" class="btn see_more btn-small" :class="{ disabled: loadingMore }"
                @click="fetchMore()" v-if="hasMore">
                {{ loadingMore ? 'Carregando...' : 'Mostrar mais' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AssistantManager from "../../../js/services/assistant.js";
import StringUtils from "../../../js/utils/string.js";
import Config from "../../../../resources/config.js";

const RESULT_COUNT = 10;

import debounce from "lodash.debounce";

const debouncedHandler = debounce((event, func) => {
  func(event.target.value);
}, 500);

export default {
  components: { SideBar, TopBar },

  beforeUnmount() {
    debouncedHandler.cancel();
  },

  created() {
    this.$store.commit("setActiveSection", "ProjectListPage");
    this.$store.commit("setActiveProjectList", null);
    this.loadProjects();
  },

  data() {
    return {
      searching: false,
      profile: null,
      projects: [],
      searchError: false,
      loadingMore: false,
      hasMore: true,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },

    projectCount() {
      if (this.projects) {
        return this.projects.length;
      }
      return 0;
    },

    projectSummary() {
      return "Projetos";
    },
  },

  methods: {
    async loadProjects() {
      this.$store.commit("setLoading", true);
      try {
        let response = await AssistantManager.searchProjects(null, true, null, RESULT_COUNT);
        this.profile = response.profile;
        this.projects = response.projects;
        this.hasMore = this.projects.length >= RESULT_COUNT;
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setLoading", false);
        if (!this.profile.telegram_id) {
          $("#communityModal").modal("show");
        //   await AssistantManager.setSeenIntroAv();
        }
        this.applyTruncation();
      } catch (error) {
        this.handleAPIError(error, "projetos");
      }
    },

    debounceSearch(e) {
      debouncedHandler(e, () => {
        this.searchProjects();
      });
    },

    async searchProjects() {
      let ctx = this;
      ctx.searching = true;
      ctx.searchError = false;
      try {
        let response = await AssistantManager.searchProjects(
          $("#searchTalentInput").val(),
          false,
          null,
          RESULT_COUNT
        );

        this.projects = response.projects;
        this.hasMore = response.projects.length >= RESULT_COUNT;
      } catch (err) {
        console.log(err);
        ctx.searchError = true;
      } finally {
        ctx.searching = false;
        ctx.$nextTick(() => {
          $("#searchTalentInput").focus();
          ctx.applyTruncation();
        });
      }
    },

    async fetchMore() {
      let ctx = this;
      ctx.loadingMore = true;
      ctx.searchError = false;
      let lastProject = null;
      if (this.projects && this.projects.length > 0) {
        lastProject = this.projects[this.projects.length - 1];
      }
      try {
        let response = await AssistantManager.searchProjects(
          $("#searchTalentInput").val(),
          false,
          lastProject,
          RESULT_COUNT
        );

        this.projects = this.projects.concat(response.projects);
        if (response.projects.length < RESULT_COUNT) {
          this.hasMore = false;
        }

        ctx.$nextTick(() => {
          ctx.applyTruncation(() => {

            if (response.projects.length > 0) {
              let card = $(".talent-page").find('#' + response.projects[0].id).get(0);
              let navBar = $(".body-container .element .nav").get(0);
              let scrollDiv = card.offsetTop - navBar.offsetHeight - 116;
              window.scrollTo({ top: scrollDiv, behavior: 'smooth' });

            }
          });
        });

      } catch (err) {
        console.log(err);
      } finally {
        ctx.loadingMore = false;
      }
    }
  },
};
</script>
