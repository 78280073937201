<template>
  <div class="side_navbar">
    <div class="user_profile">
      <a href="/perfil">
        <img :src="userPicture" v-if="userPicture" alt="" />
        <img class="empty-image" v-else alt="" />
      </a>
      <p class="client_name"><a href="/perfil">{{ userName }}</a></p>

      <div class="review mb-3">
        <a href="/perfil">
          <div class="star">                                
            <i :class="star.class" class="icons" v-for="star in getRatingStars(rating)" :key="star.id"></i>
            <span class="rating" style="font-size:10pt"> ({{ rating.toFixed(2) }})</span>
          </div>
        </a>
      </div>

      <div class="text-center mt-2">
        <a class="link" :href="logoutURL">Sair</a>
      </div>
    </div>
    <div class="nav">
      <ul>
        <li>
          <a class="nav-link" :class="{ active: this.section === 'DashboardPage' }" href="/"><i
              class="fa-solid fa-house icons"></i>Dashboard</a>
        </li>
       <li>
          <a class="nav-link" href="/perfil" :class="{ active: this.section === 'ProfilePage' }"><i
              class="fa-regular fa-solid fa-user icons" style="padding-left: 3px; margin-right: 12px"></i>Perfil</a>
        </li>
        <li>
          <a class="nav-link" href="/plano" :class="{ active: this.section === 'PlanPage' }"><i
              class="fa-regular fa-credit-card" style="padding-left: 0px; margin-right: 12px"></i>Planos</a>
        </li>
        <li>
          <a class="nav-link" href="/cursos" :class="{ active: this.section === 'CourseListPage' || this.section === 'CoursePage' || this.section === 'ClassPage'   }"><i
              class="fa-solid fa-graduation-cap icons"  style="padding-left: 2px; margin-right: 9px"></i>Cursos</a>
        </li>
        <li>
          <a class="nav-link" href="/video-aulas"
            :class="{ active: this.section === 'VideoListPage' || this.section === 'VideoPage' }"><i
              class="fa-brands fa-youtube icons" style="padding-left: 1px; margin-right: 10px"></i>Aulas exclusivas</a>
        </li>
        <li>
          <a class="nav-link" href="/comunidade" :class="{ active: this.section === 'CommunityPage' }"><i
              class="fa-solid fa-users icons"></i>Comunidade</a>
        </li>
        <li>
          <a class="nav-link" href="/projetos"
            :class="{ active: this.section === 'ProjectListPage' || this.section === 'ProjectPage' || this.section === 'ClientPage' }"><i
              class="fa-solid fa-bag-shopping icons"  style="padding-left: 3px; margin-right: 11px"></i>Encontre trabalho</a>
        </li>
        <li>
          <a class="nav-link" href="/propostas" :class="{
            active:
              this.section === 'ProposalListPage' ||
              this.section === 'ProposalPage'
          }"><i class="fa-solid fa-file-lines icons"  style="padding-left: 5px; margin-right: 12px"></i>Minhas propostas
          </a>
        </li>
        <!-- <li>
          <a class="nav-link" href="/ferramentas" :class="{ active: this.section === 'ToolListPage' }"><i
              class="fa-solid fa-gears icons"></i>Ferramentas</a>
        </li> -->
        <!-- <li>
          <a
            class="nav-link"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#support"
            ><i class="fa-solid fa-headset icons"></i>Suporte</a
          >
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import Config from "../../../resources/config.js";

export default {
  data() {
    return {};
  },
  computed: {
    section() {
      return this.$store.state.activeSection;
    },
    rating() {
      let user = this.$store.state.activeUser;
      if (user) {
        return user.feedback_rating;
      }
      return null;
    },
    userName() {
      let user = this.$store.state.activeUser;
      if (user) {
        return user.name;
      }
      return null;
    },
    userPicture() {
      let user = this.$store.state.activeUser;
      if (user && user.picture) {
        return this.getImageURL(user.picture);
      }
      return null;
    },
    logoutURL() {
      return `${Config.apiHost}/logout`;
    },
  },

  methods: {},
};
</script>
