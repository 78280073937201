<template>
  <div class="loading_container">
    <div class="alert alert-danger m-5">
      Ocorreu um erro ao efetuar o log-in.<br />
      <a :href="loginLink" class="link">Clique aqui para tentar novamente</a>.
    </div>

  </div>
</template>

<script>
import Config from "../../../../resources/config.js";

export default {

  created() { },

  components: {},

  computed: {
    loginLink() {
      let url = Config.apiHost + '/auth/google/assistant';
      if (this.$route.query.popup === '1') {
        url += '?popup=true';
      } else if (this.$route.query.next) {
        url += '?next=' + this.$route.query.next.substring(1)
      }
      return url;
    }
  },
  data() {
    return {
    };
  },

  methods: {},
};
</script>
