<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container">
        <div class="">
          <div class="alert alert-warning mb-3" v-if="profile.status == 1">
            Seu perfil está sendo analisado pela nossa equipe. Assim que for aprovado, você poderá editá-lo.
          </div>
          <div class="element" style="margin-right: 0">
            <div class="tab">
              <h1>Perfil</h1>
              <a class="btn btn-small" @click="editProfile()" :disabled="profile.status !== 2"
                :class="{ 'disabled': profile.status !== 2 }"><i class="fa fa-pencil" style="padding-right: 5px"></i>
                Editar</a>
            </div>
            <div class="freelancer_profile">
              <div class="row">
                <div class="col-lg-12">
                  <div class="talent_profile section" v-if="profile !== null">
                    <div class="talent-info">
                      <div class="freelancer_info">
                        <div class="freelancer">
                          <a href="javascript:void(0)" @click="selectFile()"
                            :class="{ 'disabled': profile.status !== 2 }">
                            <div class="img-container" :class="{ 'disabled': profile.status !== 2 }">
                              <img class="freelancer_img" :src="getImageURL(profile.picture)" v-if="profile.picture"
                                alt="" />
                              <img class="freelancer_img empty-image" v-else alt="" />
                              <span>
                                <i class="fa fa-pencil"></i>
                              </span>
                              <input type="file" accept="image/*" id="finput" @change="uploadImage()"
                                style="display:none">
                            </div>
                          </a>
                          <div class="info">
                            <div>
                              <a>
                                <h2 class="break-word">
                                  {{ profile.name }}
                                </h2>
                              </a>
                              <p class="break-word title">{{ profile.title }}</p>
                            </div>
                            <div class="down-icons-list">

                              <div class="ex_icon" v-if="profile.cert_av || profile.cert_ap">
                                <span tabindex="0" data-bs-placement='bottom' data-bs-toggle="popover"
                                  :data-bs-title="getCervAVTitle()" data-bs-custom-class="certav-popover"
                                  :data-bs-content="getCertAVDescription()" data-bs-html="true" v-if="profile.cert_av"
                                  class="cert_av"><i class="fa-solid fa-laptop icons"></i></span>

                                <span tabindex="0" data-bs-placement="bottom" data-bs-toggle="popover"
                                  :data-bs-title="getCervAPTitle()" :data-bs-content="getCertAPDescription()"
                                  data-bs-html="true" data-bs-custom-class="certap-popover" v-if="profile.cert_ap"
                                  class="cert_ap"><i class="fa-solid fa-star icons"></i></span>
                                <!-- <span><i class="fa-solid fa-trophy icons"></i></span> -->
                              </div>

                              <div class="star">                                
                                <i :class="star.class" class="icons" v-for="star in getRatingStars(profile.feedback_rating)" :key="star.id"></i>
                                <span class="rating"> ({{ profile.feedback_rating.toFixed(2) }})</span>
                              </div>
                            </div>
                            <p class="country">{{ profile.location }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="keywords-group">
                      <div class="ky_btn" v-if="profile.area_ap">
                        Assistência Pessoal
                      </div>
                      <div class="ky_btn" v-if="profile.area_ae">
                        Secretariado Executivo
                      </div>
                      <div class="ky_btn" v-if="profile.area_sa">
                        Suporte Administrativo
                      </div>
                      <div class="ky_btn" v-if="profile.area_sr">
                        Secretariado remoto
                      </div>
                      <div class="ky_btn" v-if="profile.area_si">
                        Suporte para Infoprodutores
                      </div>
                      <div class="ky_btn" v-if="profile.area_ed">
                        Entrada de dados
                      </div>
                    </div>
                  </div>



                  <div class="row gy-4 certs" style="margin-top:30px;" v-if="profile.cert_av || profile.cert_ap">
                    <div class="col-sm-12 col-md-12 cert-item" v-if="profile.cert_av">
                      <div class="certav-box"><strong>Profissional certificado em Assistência Virtual</strong>
                        <div class="cert-container">
                          <i class="fa fa-laptop cert_av"></i>
                          <div>
                            <div class="break-word"> Este é um treinamento introdutório em Assistência Virtual fornecido
                              pela
                              Virtap.</div>

                            <div class="break-word">Elaborado por profissionais que
                              acumulam 10 anos de
                              experiência no mercado.</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-12 cert-item" v-if="profile.cert_ap">
                      <div class="certap-box">
                        <strong>Profissional
                          certificado em Assessoria Pessoal</strong>
                        <div class="cert-container">
                          <i class="fa fa-star cert_ap"></i>
                          <div>
                            <div class="break-word"> Fornecido pela Virtap, este é o treinamento mais
                              completo em Assistência Pessoal do Brasil.</div>

                            <div class="break-word">Elaborado por profissionais que
                              acumulam 10 anos de
                              experiência no mercado, este treinamento é composto por mais de 60 aulas divididas em 9
                              módulos, que
                              vão desde a execução
                              de tarefas, ferramentas, até o aprendizado de softskills que são essenciais para se
                              trabalhar nessa
                              área. </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                 

                  <div style="margin-top: 30px">
                    <div class="tab">
                      <h1>Sobre mim</h1>
                      <a class="btn btn-small" @click="editDescription()" :disabled="profile.status !== 2"
                        :class="{ 'disabled': profile.status !== 2 }"><i class="fa fa-pencil"
                          style="padding-right: 5px"></i> Editar</a>
                    </div>
                    <div class="description-part section">
                      <p class="break-word" style="font-size: 14.2px" v-html="formatMultilineText(profile.description)">
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="seller_review">
              <div class="tab">
                <h1>Minhas avaliações <span class="text-muted fs-6">({{profile.reviews.length}})</span></h1>
              </div>
              <div class="section" style="padding:0" v-if="profile.reviews.length > 0">
                <div class="proposals-list"  style="height: auto;max-height: 100%; padding-bottom: 0">
                  <div class="proposal-container">
                    <div class="proposal" style="padding:20px; cursor: pointer" v-for="review in profile.reviews" :key="review.project_id" @click="showProject(review.project_id)">
                      <div class="proposal-info">
                          <p class="name break-all" style="font-size:14.2px">{{review.title}}</p>
                          <p class="project" style="font-style: italic; font-size: 9pt;  display: flex; gap: 5px; flex-wrap: wrap; align-items: center" @click="showClient(review.client_id)">
                            <img class="freelancer_img" style="width:50px; height: 50px;" :src="getImageURL(review.picture)" v-if="review.picture"/>
                            <img class="empty-image" style="width:50px; height: 50px;" v-else/>
                            <span class="break-all">{{review.name}}</span>
                          </p>
                          <p class="project mt-1" style="margin-left: -5px">
                            <i :class="star.class" class="icons" v-for="star in getRatingStars(review.assistant_rating)" :key="star.id" style="font-size: 15px"></i>
                                <span class="rating"> ({{ review.assistant_rating.toFixed(2) }})</span>
                          </p>
                          <p class="date">{{formatElapsedTime(review.release_timestamp)}}</p>
                          <p class="project mt-2 break-all break-line">{{review.assistant_comment}}</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="section text-center" v-else>
                <h2 class="light p-2">
                  <div class="d-flex" style="align-items: center; gap: 5px; flex-wrap: wrap; justify-content: center">
                    <i class="fa-regular fa-star" style="font-size: 30px"></i>
                    <span>Nenhuma avaliação</span>
                  </div>
                </h2>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AssistantManager from "../../../js/services/assistant.js";
import StringUtils from "../../../js/utils/string.js";
import FileUtils from "../../../js/utils/file.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "ProfilePage");
    this.$store.commit("setActiveProjectList", null);
    this.loadClientProfile();
  },

  data() {
    return {
      profile: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadClientProfile() {
      this.$store.commit("setLoading", true);
      try {
        this.profile = await AssistantManager.getProfile();
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setLoading", false);
        let ctx = this;
        this.$nextTick(() => {
          ctx.initTooltips();

        });
      } catch (error) {
        this.handleAPIError(error, "perfil");
      }
    },

    selectFile() {
      if (this.profile.status === 1) {
        this.showError(
          "Seu perfil ainda está sendo analisado.\n\nAssim que for aprovado, você poderá editá-lo."
        );

        return false;
      }
      document.getElementById('finput').click();
    },

    manageSubscription() {
      window.open(Config.apiHost + '/assistant/manage-subscription');

    },

    showProject(project_id) {
      window.open('/projeto/' + project_id);
    },

    showClient(client_id) {
      window.open('/cliente/' + client_id);
    },

    uploadImage() {

      let $ctx = this;

      if (this.profile.status === 1) {
        this.showError(
          "Seu perfil ainda está sendo analisado.\n\nAssim que for aprovado, você poderá editá-lo."
        );
        return;
      }

      var fileinput = document.getElementById("finput");
      if (fileinput.files.length > 0) {
        let reader = new FileReader();
        reader.onload = function (event) {


          let $modal = $("#editImageModal");


          let cropper = null;

          let action = async function () {
            let base64 = await cropper.cropme('crop', 'base64');
            let compressedImage = await FileUtils.downscaleImage(base64, 'image/jpeg', 240, 0.9);
            let blob = FileUtils.dataURIToBlob(compressedImage);

            let assistantDetails = {
              picture: new File([blob], "image.jpeg")
            }
            let updatedAssistant = await AssistantManager.updateProfile(
              assistantDetails
            );

            $ctx.profile = updatedAssistant;
            $ctx.$store.commit("setActiveUser", $ctx.profile);
            return true;

          };

          $('#editImgContainer').html('<div class="text-center">Carregando...</div>');

          $ctx.showModal(
            $modal,
            function () {
              $('#editImgContainer').html('<img data-img class=""></img>');
              let $img = $("#editImgContainer").find('img').attr('src', event.target.result);
              $img.on('load', function () {
                $img.off();
                cropper = $img.cropme({
                  container: {
                    width: '100%'
                  },
                  viewport: {
                    width: 240,
                    height: 240,
                    border: {
                      color: 'var(--bs-blue)'
                      //type: 'circle'
                    },

                  }, zoom: {
                    slider: true
                  },
                  rotation: {
                    slider: false
                  }



                });

              });
            },
            "Salvar",
            action,
            "Perfil atualizado.",
            "Ocorreu um erro editar o perfil.\nPor favor, tente novamente. Se o problema persistir, atualize a página.",
            function () {
              $("#finput").val("");
              if (cropper) {
                cropper.cropme('destroy');
              }

            }
          );

          // 
          // $("#editProfileImg").data('file', fileinput.files[0]);
        };
        reader.readAsDataURL(fileinput.files[0]);
      }
    },

    editProfile() {
      let $ctx = this;

      if (this.profile.status === 1) {
        this.showError(
          "Seu perfil ainda está sendo analisado.\n\nAssim que for aprovado, você poderá editá-lo."
        );
        return;
      }

      let $modal = $("#editProfileModal");
      let $name = $modal.find("[data-assistant-name]");
      let $title = $modal.find("[data-assistant-title]");
      let $location = $modal.find("[data-assistant-location]");
      let $phone = $modal.find("[data-assistant-phone]");
      let $chkAreas = $("#areasAtuacao");
      let $chkCerts = $("#certificacoes");

      $modal.find(".item").removeClass("error");
      $name.val($ctx.profile.name);
      $title.val($ctx.profile.title);
      $location.val($ctx.profile.location);

      $modal.find("input[type=checkbox]").prop("checked", false);

      if (!$modal.data('init')) {
        let cidades = window.global.cidades;
        $location.autoComplete({
          minChars: 3,
          appendTo: '#location-suggestions',
          source: function (term, suggest) {
            term = term.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            var matches = [];
            for (let i = 0; i < cidades.length; i++) {
              if (~cidades[i].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(term)) {
                matches.push(cidades[i]);
              }
            }
            suggest(matches);
          },

          renderItem: function (item, search) {
            return '<div class="autocomplete-suggestion" data-val="' + item + '">' + item + '</div>';
          },

        });


        let intl = window.intlTelInput($phone[0], {
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
          autoInsertDialCode: true,
          initialCountry: "BR",
          separateDialCode: true
        });


        $phone.on('blur', function () {
          intl.setNumber(intl.getNumber());
          if (intl.isValidNumber()) {
            $phone.closest(".item").removeClass("error");
          } else {
            $phone.closest(".item").addClass("error");
          }
        });

        $modal.data('init', intl);
      }

      $modal.data('init').setNumber($ctx.profile.phone);

      if ($ctx.profile.cert_av) {
        $chkCerts
          .find("input[type=checkbox][value='ASSISTENTE_VIRTUAL'")
          .prop("checked", true);
      }

      if ($ctx.profile.cert_ap) {
        $chkCerts
          .find("input[type=checkbox][value='ASSISTENTE_PESSOAL'")
          .prop("checked", true);
      }

      if ($ctx.profile.area_ap) {
        $chkAreas
          .find("input[type=checkbox][value='ASSISTENCIA_PESSOAL'")
          .prop("checked", true);
      }

      if ($ctx.profile.area_ae) {
        $chkAreas
          .find("input[type=checkbox][value='SECRETARIADO_EXECUTIVO'")
          .prop("checked", true);
      }

      if ($ctx.profile.area_sa) {
        $chkAreas
          .find("input[type=checkbox][value='SUPORTE_ADMINISTRATIVO'")
          .prop("checked", true);
      }

      if ($ctx.profile.area_sr) {
        $chkAreas
          .find("input[type=checkbox][value='SECRETARIADO_REMOTO'")
          .prop("checked", true);
      }

      if ($ctx.profile.area_si) {
        $modal
          .find("input[type=checkbox][value='SUPORTE_PARA_INFOPRODUTORES'")
          .prop("checked", true);
      }

      if ($ctx.profile.area_ed) {
        $chkAreas
          .find("input[type=checkbox][value='ENTRADA_DE_DADOS'")
          .prop("checked", true);
      }

      let action = async function () {
        let selectedAreas = [];
        let selectedCertifications = [];

        $chkAreas.find("input[type=checkbox]:checked").each(function (i, e) {
          selectedAreas.push($(e).attr("value"));
        });

        $chkCerts.find("input[type=checkbox]:checked").each(function (i, e) {
          selectedCertifications.push($(e).attr("value"));
        });

        let assistantDetails = {
          name: $name.val(),
          title: $title.val(),
          location: $location.val(),
          expertise_areas: selectedAreas,
          certifications: selectedCertifications,
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (assistantDetails.name.length < 10) {
          $name.closest(".item").addClass("error");
          hasError = true;
        }

        if (assistantDetails.title.length < 10) {
          $title.closest(".item").addClass("error");
          hasError = true;
        }

        if (assistantDetails.location.length < 10) {
          $location.closest(".item").addClass("error");
          hasError = true;
        }

        if ($phone.val().length < 1 || !$modal.data('init').isValidNumber()) {
          $phone.closest(".item").addClass("error");
          hasError = true;
        } else {
          assistantDetails.phone = $modal.data('init').getNumber();
        }



        if (
          assistantDetails.expertise_areas.length === 0 ||
          assistantDetails.expertise_areas.length > 3
        ) {
          $chkAreas.closest(".item").addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        let updatedAssistant = await AssistantManager.updateProfile(
          assistantDetails
        );

        $ctx.profile = updatedAssistant;
        $ctx.$store.commit("setActiveUser", $ctx.profile);
        return true;
      };

      this.showModal(
        $modal,
        function () {
          $modal.find("[data-assistant-name]").focus();
        },
        "Salvar",
        action,
        "Perfil atualizado.",
        "Ocorreu um erro editar o perfil.\nPor favor, tente novamente. Se o problema persistir, atualize a página."
      );
    },

    editDescription() {
      let $ctx = this;

      if (this.profile.status === 1) {
        this.showError(
          "Seu perfil ainda está sendo analisado.\n\nAssim que for aprovado, você poderá editá-lo."
        );
        return;
      }

      let $modal = $("#editDescriptionModal");
      let $description = $modal.find("[data-assistant-description]");

      $modal.find(".item").removeClass("error");
      $description.val($ctx.profile.description);
      $description.css('height', '');
      $description.off().on('input', function () {
        $description.css('height', 'auto');
        $description.css('height', Math.max($description[0].scrollHeight, 200) + "px");
      });


      let action = async function () {

        let assistantDetails = {
          description: $description.val(),
        };

        let hasError = false;
        $modal.find(".item").removeClass("error");

        if (assistantDetails.description.length < 100) {
          $description.closest(".item").addClass("error");
          hasError = true;
        }

        if (hasError) {
          return false;
        }

        let updatedAssistant = await AssistantManager.updateProfile(
          assistantDetails
        );

        $ctx.profile = updatedAssistant;
        $ctx.$store.commit("setActiveUser", $ctx.profile);
        return true;
      };

      this.showModal(
        $modal,
        function () {
          $description.trigger('input');
          $modal.find("[data-assistant-description]").focus();
        },
        "Salvar",
        action,
        "Perfil atualizado.",
        "Ocorreu um erro editar o perfil.\nPor favor, tente novamente. Se o problema persistir, atualize a página.",
        function () {
          $description.val("");
          $description.css('height', '200px');
        }
      );
    }
  },
};
</script>
