<template>
  <div class="loading_container" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div class="main" v-else>
    <!-- Side Navbar -->
    <SideBar />
    <!-- Body-Content -->
    <div class="body-content">
      <!-- Top Navbar -->
      <TopBar />
      <!-- Body -->
      <div class="container-fluid body-container plan-page">
        <div class="center-block" v-if="profile.status == 1">
          <div>
            <div class="alert alert-warning mb-0">
              Seu perfil está sendo analisado pela nossa equipe. Assim que for aprovado,
              você poderá gerenciar seu plano.
            </div>
          </div>
        </div>
        <div v-else>
          <div class="alert alert-warning mb-4" v-if="profile.plan === 'Trial'">
            Você está com acesso ao plano <strong>TRIAL</strong> da plataforma da Virtap
            por 12 meses, com todos os benefícios do plano pago. Seu plano será
            automaticamente convertido para o plano <strong>FREE</strong> após o final
            dos 12 meses de acesso, a contar do dia 22/07/2024.
          </div>
          <div class="row gy-4">
            <div class="col-lg-6">
              <div class="tab">
                <h1>Plano Free</h1>
                <a class="btn btn-small" style="visibility: hidden">.</a>
              </div>
              <div class="description-part section plan">
                <div style="padding-top: 0.2rem">
                  <strong class="price">Gratuito</strong>
                  <em class="current-plan" v-if="profile.plan === 'Free'">
                    (seu plano)</em
                  >
                  <em class="current-plan" v-else style="visibility: hidden">.</em>
                  <ul class="mt-4">
                    <!-- <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Curso
                      grátis de AV
                    </li> -->
                    <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Comunidade
                      Virtap
                    </li>
                    <li>
                      <i class="fas fa-check-circle" style="color: #eb4d4b"></i>Até 1
                      proposta mensal
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="tab" style="background: #7fcfd333; border-bottom: 0">
                <h1>Plano Starter</h1>
                <a
                  class="btn btn-small"
                  @click="manageSubscription()"
                  v-if="profile.subscription_id && profile.plan === 'Starter'"
                  ><i class="fa fa-gear" style="padding-right: 5px"></i> Gerenciar</a
                >
                <a
                  class="btn btn-small"
                  @click="subscribe('Starter')"
                  v-else-if="profile.plan === 'Free'"
                  ><i class="fa fa-cart-shopping" style="padding-right: 5px"></i>
                  Assinar</a
                >
                <a class="btn btn-small" style="visibility: hidden" v-else>.</a>
              </div>

              <div class="description-part section plan" style="background: #7fcfd333">
                <div style="padding-top: 0.2rem">
                  <strong class="price">R$ 49,90/mês</strong>
                  <em class="current-plan" v-if="profile.plan === 'Starter'">
                    (seu plano)</em
                  >
                  <em class="current-plan" v-else style="visibility: hidden">.</em>
                  <ul class="mt-4">
                    <!-- <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Curso
                      grátis de AV
                    </li> -->
                    <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Comunidade
                      Virtap
                    </li>
                    <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Propostas
                      ilimitadas
                    </li>
                    <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Vídeo
                      aulas exclusivas
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- <div class="col-lg-4">
              <div class="tab" style="background: #e4daf3; border-bottom: 0">
                <h1>Plano Vip</h1>
                <a
                  class="btn btn-small"
                  @click="manageSubscription()"
                  v-if="profile.subscription_id && profile.plan === 'Vip'"
                  ><i class="fa fa-gear" style="padding-right: 5px"></i> Gerenciar</a
                >
                <a
                  class="btn btn-small"
                  @click="subscribe('Vip')"
                  v-else-if="profile.plan === 'Free'"
                  ><i class="fa fa-cart-shopping" style="padding-right: 5px"></i>
                  Assinar</a
                >
                <a class="btn btn-small" style="visibility: hidden" v-else>.</a>
              </div>

              <div class="description-part section plan" style="background: #e4daf3">
                <div style="padding-top: 0.2rem">
                  <strong class="price">R$ 897,90/ano</strong>
                  <em class="current-plan" v-if="profile.plan === 'Vip'"> (seu plano)</em>
                  <em class="current-plan" v-else style="visibility: hidden">.</em>
                  <ul class="mt-4">
                    <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Curso
                      grátis de AV
                    </li>
                    <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Comunidade
                      Virtap
                    </li>
                    <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Propostas
                      ilimitadas
                    </li>
                    <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Vídeo
                      aulas exclusivas
                    </li>
                    <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Formação
                      em Assistência Virtual
                    </li>
                    <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Prioridade
                      nas propostas
                    </li>
                    <li>
                      <i class="fas fa-check-circle" style="color: #6ab04c"></i>Selo Vip
                      no perfil
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "../SideBar.vue";
import TopBar from "../TopBar.vue";
import AssistantManager from "../../../js/services/assistant.js";
import StringUtils from "../../../js/utils/string.js";
import FileUtils from "../../../js/utils/file.js";
import Config from "../../../../resources/config.js";

export default {
  components: { SideBar, TopBar },

  created() {
    this.$store.commit("setActiveSection", "PlanPage");
    this.$store.commit("setActiveProjectList", null);
    this.loadClientProfile();
  },

  data() {
    return {
      profile: null,
    };
  },

  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },

  methods: {
    async loadClientProfile() {
      this.$store.commit("setLoading", true);
      try {
        this.profile = await AssistantManager.getPlan();
        this.$store.commit("setActiveUser", this.profile);
        this.$store.commit("setLoading", false);
      } catch (error) {
        this.handleAPIError(error, "plano");
      }
    },

    subscribe(plan) {
      window.open(Config.apiHost + "/assistant/subscribe/" + plan);
    },

    manageSubscription() {
      window.open(Config.apiHost + "/assistant/manage-subscription");
    },
  },
};
</script>
